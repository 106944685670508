export const COLORS = {
  white: '#fff',
  primaryBlue: '#3380EE',
  primaryAverage: '#C6DBFA',
  lightBlue: '#EBF2FD',  
  primaryDarkBlue: '#1562CD',
  primaryExtraLight: '#F5F8FF',
  grayscaleLight: '#F1F3F8',
  grayscaleDark: '#2D2D40',
  grayscaleDarkmode: '#777998',
  grayscaleAverage: '#C2CADF',
};
