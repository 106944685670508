import * as React from "react";

function Close(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8413 17.7534C19.1134 17.4802 19.1134 17.0385 18.8413 16.7654L12.5943 10.4938C12.3223 10.2207 12.3223 9.77896 12.5943 9.50582L18.8413 3.23425C19.1134 2.96111 19.1134 2.51939 18.8413 2.24625L17.7637 1.1644C17.4901 0.889739 17.0454 0.889739 16.7718 1.16441L10.4959 7.46498C10.2224 7.73965 9.77764 7.73965 9.50405 7.46498L3.22824 1.16444C2.95465 0.889779 2.50993 0.889779 2.23635 1.16445L1.15875 2.24629C0.886678 2.51943 0.886678 2.96114 1.15875 3.23429L7.40571 9.50586C7.67778 9.779 7.67778 10.2207 7.40571 10.4939L1.15875 16.7654C0.886678 17.0386 0.886678 17.4803 1.15875 17.7534L2.23635 18.8353C2.50994 19.1099 2.95465 19.1099 3.22824 18.8353L9.50409 12.5347C9.77768 12.26 10.2224 12.26 10.496 12.5347L16.7718 18.8352C17.0454 19.1099 17.4901 19.1099 17.7637 18.8352L18.8413 17.7534Z"
        fill="#777998"
      />
    </svg>
  );
}

export default Close;
