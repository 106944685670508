import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Container } from '../Container';
import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Footer: FC = () => {
  const { t } = useTranslation('footer');

  const menuList = [
    {
      id: t('particular'),
      text: t('particular'),
      link: 'particular',
    },
    {
      id: t('advantages'),
      text: t('advantages'),
      link: 'advantages',
    },
    {
      id: t('request'),
      text: t('request'),
      link: 'request',
    },
  ];

  const submenuList = [
    {
      id: t('cookie'),
      text: t('cookie'),
      link: LINKS.cookiePolicy,
    },
    {
      id: t('policy'),
      text: t('policy'),
      link: LINKS.privacyPolicy,
    },
  ];

  return (
    <Root>
      <StyledContainer>
        <FooterWrapper>
          {/*<Logo>*/}
            {/*<span>ЛОГОТИП</span>*/}
          {/*</Logo>*/}

          <MainMenu>
            {menuList.map((item) => (
              <div key={item.id}>
                <ScrollLink
                  to={item.link}
                  smooth={true}
                  duration={600}
                  offset={-66}
                >
                  {item.text}
                </ScrollLink>
              </div>
            ))}
          </MainMenu>

          <SubMenu>
            {submenuList.map((item) => (
              <div key={item.id}>
                <a href={item.link} target="_blank">
                  {item.text}
                </a>
              </div>
            ))}
          </SubMenu>

          <Contacts>
            {/*<div>*/}
            {/*  <a href="tel:+74992951135">+7 (499) 295-11-35</a>*/}
            {/*</div>*/}
          </Contacts>

          {/*<Address>*/}
          {/*  <div>*/}
          {/*    <span>{t('address')}</span>*/}
          {/*  </div>*/}
          {/*</Address>*/}
          <Copyright>
            <span>© 2023 {t('copyright')}</span>
          </Copyright>
        </FooterWrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.footer`
  background-color: ${COLORS.primaryDarkBlue};
`;

const StyledContainer = styled(Container)`
  padding-top: 72px;
  padding-bottom: 72px;
  @media (max-width: 768px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

const FooterWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'Logo MainMenu SubMenu Contacts Address'
    'Logo MainMenu SubMenu Contacts Copyright';
  grid-template-rows: min-content 1fr;
  column-gap: 80px;
  @media (max-width: 1200px) {
    grid-template-areas:
      'Logo MainMenu SubMenu Contacts Address'
      'Logo MainMenu SubMenu Contacts Copyright';
    grid-template-rows: min-content 1fr;
    column-gap: 26px;
  }
  @media (max-width: 768px) {
    grid-template-areas:
      'SubMenu SubMenu'
      'Contacts Contacts'
      'Address Address'
      'MainMenu .'
      'MainMenu .'
      'Logo .'
      'Copyright .';
    grid-template-rows: min-content min-content min-content 1fr min-content;
    grid-template-columns: 1fr 1fr;
  }
`;

const Logo = styled.div`
  grid-area: Logo;
  display: flex;
  align-items: center;
  span {
    ${TYPOGRAPHY.bodyReg22};
    color: ${COLORS.white};
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
    align-items: flex-end;
    margin-top: 30px;
    span {
      font-size: 28px;
    }
  }
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
  div {
    margin-bottom: 16px;
    ${TYPOGRAPHY.footerReg12}
    :last-child {
      margin-right: 0;
    }
  }
`;

const MainMenu = styled(MenuItem)`
  grid-area: MainMenu;
  a {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const SubMenu = styled(MenuItem)`
  grid-area: SubMenu;
`;

const Contacts = styled(MenuItem)`
  grid-area: Contacts;
`;

const Address = styled(MenuItem)`
  grid-area: Address;
`;

const Copyright = styled.div`
  grid-area: Copyright;
  ${TYPOGRAPHY.footerReg12};
  display: flex;
  flex-direction: column;
  opacity: 0.7;
  span {
    color: ${COLORS.lightBlue};
  }
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

export default Footer;
