import React, { FC } from 'react';
import { Cookie } from '@/components/Cookie';
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import styled from 'styled-components';
import { ArrowToTop } from '@/components/ArrowToTop';
import { CookiesProvider } from 'react-cookie';

interface MainLayoutProps {}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <CookiesProvider>
      <Root>
        <Header />
        {children}
        <Footer />
        <ArrowToTop />
        <Cookie />
      </Root>
    </CookiesProvider>
  );
};

const Root = styled.div`
  position: relative;
`;

export default MainLayout;
