import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/ui/components/Button';
import styled from 'styled-components';
import { Cookies, useCookies } from 'react-cookie';

interface CookieProps {
  className?: string;
}

const Cookie: FC<CookieProps> = ({ className }) => {
  const { t } = useTranslation('common');
  const [cookies, setCookie] = useCookies(['acceptCookie']);
  const [isCookie, setIsCookie] = useState(true);
  const cookisInstance = new Cookies();

  useEffect(() => {
    window.addEventListener('load', () => {
      setIsCookie(cookisInstance.get('acceptCookie') || false);
    });
  }, []);

  return (
    <Root
      className={className}
      style={{
        display: isCookie ? 'none' : 'block',
      }}
    >
      <CookieWrapper id="cookie">
        <div>
          <Trans i18nKey="cookie" t={t}>
            <span>
              Мы используем файлы cookie, чтобы улучшить работу нашего сайта.
            </span>
            <br />
            <span>
              Для получения дополнительной информации, пожалуйста, ознакомьтесь
              с нашей Политикой использования файлов cookie
            </span>
          </Trans>
        </div>

        <ButtonWrapper>
          <StyledLink href={LINKS.cookiePolicy} target="_blank">
            <Trans i18nKey="policy" t={t}>
              Политика использования
            </Trans>
          </StyledLink>

          <StyledButton
            onClick={() => {
              setIsCookie(true);
              setCookie('acceptCookie', true, {
                expires: new Date(
                  new Date().getTime() + 1000 * 60 * 60 * 24 * 365
                ),
              });
            }}
          >
            <Trans i18nKey="accept" t={t}>
              Принять
            </Trans>
          </StyledButton>
        </ButtonWrapper>
      </CookieWrapper>
    </Root>
  );
};

const Root = styled.div``;

const CookieWrapper = styled.div`
  z-index: 100;
  position: fixed;
  border-radius: 8px;
  width: 100vw;
  padding: 40px 0px 40px 40px;
  left: 0;
  bottom: 0;
  background: ${COLORS.grayscaleDarkmode};
  margin: 0 auto;
  div {
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 32px;
    :last-child {
      margin-bottom: 0;
    }
    @media (min-width: 768px) {
      br {
        display: none;
      }
    }

    span {
      color: white;
      ${TYPOGRAPHY.bodyReg22};
      display: block;
      @media (max-width: 768px) {
        ${TYPOGRAPHY.bodyReg16M};
      }
    }
  }

  @media (max-width: 768px) {
    padding: 16px 16px 24px 16px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 0px !important;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  font-weight: 600;
  width: 100%;
  max-width: 100%;

  @media (min-width: 461px) {
    max-width: 260px;
  }
`;

const StyledLink = styled.a`
  padding: 14px 30px;
  text-align: center;
  max-width: 260px;
  width: 100%;
  ${TYPOGRAPHY.buttonSemiBold14};
  color: ${COLORS.primaryBlue};
  background-color: ${COLORS.lightBlue};
  border-radius: 10px;
  font-weight: 600;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
  @media (max-width: 460px) {
    width: 100%;
    max-width: 100%;
  }
  &:hover {
    background-color: ${COLORS.primaryAverage};
  }
`;

export default Cookie;
