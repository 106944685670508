import React, { FC } from 'react';
import { Link } from 'gatsby';
import { animateScroll } from 'react-scroll';

import styled from 'styled-components';
import ArrowToTopIcon from '@/assets/svg/ArrowToTopIcon';
import { COLORS } from '@/constants';

const ArrowToTop: FC = () => {
  return (
    <ArrowToTopContainer>
      <Button
        onClick={() =>
          animateScroll.scrollToTop({
            smooth: 'linear',
            duration: 600,
            delay: false,
          })
        }
      >
        <ArrowToTopIcon color={COLORS.grayscaleDark} />
      </Button>
    </ArrowToTopContainer>
  );
};

const ArrowToTopContainer = styled.div`
  z-index: 99;
  position: fixed;
  bottom: 60px;
  right: 60px;

  a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    bottom: 25px;
    right: 25px;
  }
`;

const Button = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ArrowToTop;
