import * as React from "react";

function HamburgerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 5.3335H28V8.00016H4V5.3335ZM4 14.6668H28V17.3335H4V14.6668ZM4 24.0002H28V26.6668H4V24.0002Z"
        fill="#777998"
      />
    </svg>
  );
}

export default HamburgerIcon;
