import * as React from "react";

function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67756 0.246094L10 1.56857L3.8138 7.75476L0 3.94106L1.32244 2.61858L3.8138 5.10984L8.67756 0.246094Z"
        fill="white"
      />
    </svg>
  );
}

export default CheckIcon;
