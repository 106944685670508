import React, { FC } from "react";
import { Helmet } from "react-helmet";

interface SeoProps {
  data: {
    keywords: string;
    metaDescription: string;
    metaTitle: string;
    sharedImage?: {
      url: string;
    };
  };
}

const Seo: FC<SeoProps> = ({ data }) => {
  const { metaDescription, metaTitle, keywords, sharedImage } = data;
  const url = typeof window !== "undefined" ? window.location.href : "";

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />
      {sharedImage && <meta name="image" content={sharedImage.url} />}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      {sharedImage && <meta property="og:image" content={sharedImage.url} />}
    </Helmet>
  );
};

export default Seo;
