import { COLORS, TYPOGRAPHY } from '@/constants';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import React, { FC, useRef, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Close } from '@/assets/svg';
import { Container } from '../Container';
import { HamburgerIcon } from '@/assets/svg';
import Headroom from 'react-headroom';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import styled from 'styled-components';

const Header: FC = () => {
  const { t } = useI18next('header');
  const [isActive, setIsActive] = useState(false);

  const menuList = [
    {
      id: t('particular'),
      text: t('particular'),
      link: 'particular',
    },
    {
      id: t('advantages'),
      text: t('advantages'),
      link: 'advantages',
    },
    {
      id: t('request'),
      text: t('request'),
      link: 'request',
    },
  ];

  const handleBurgerClick = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');

    if (isActive) {
      body.style.overflow = '';
      html.style.overflow = '';
      setIsActive(false);
    } else {
      body.style.overflow = 'hidden';
      html.style.overflow = 'hidden';
      setIsActive(true);
    }
  };
  return (
    <Headroom style={{ zIndex: 10 }}>
      <Root>
        <StyledContainer>
          <Hamburger>
            <div onClick={handleBurgerClick}>
              {isActive ? <Close /> : <HamburgerIcon />}
            </div>
          </Hamburger>

          {isActive && (
            <>
              <MobileMenu>
                <div>
                  {menuList.map((item) => (
                    <span key={item.id}>
                      <StyledScrollLink
                        to={item.link}
                        smooth={true}
                        duration={300}
                        onClick={() => {
                          handleBurgerClick();
                        }}
                      >
                        {item.text}
                      </StyledScrollLink>
                    </span>
                  ))}
                </div>
              </MobileMenu>
              <MobileMenuOverlay
                onClick={() => {
                  handleBurgerClick();
                }}
              />
            </>
          )}

          <Logo>
            <span>MEDICO</span>
          </Logo>
          <div>
            <Wrapper>
              <Nav>
                <Menu>
                  {menuList.map((item) => (
                    <StyledScrollLink
                      key={item.id}
                      to={item.link}
                      smooth={true}
                      duration={300}
                    >
                      {item.text}
                    </StyledScrollLink>
                  ))}
                </Menu>
              </Nav>
              {/*<LanguageSwitcher />*/}
            </Wrapper>
          </div>
        </StyledContainer>
      </Root>
    </Headroom>
  );
};

const Root = styled.header`
  border-bottom: 1px solid ${COLORS.grayscaleLight};
  z-index: 1000;
`;

const StyledContainer = styled(Container)`
  padding-top: 33px;
  padding-bottom: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 17px;
    padding-bottom: 17px;
  }
`;

const Hamburger = styled.div`
  z-index: 1002;
  div {
    width: 32px;
    height: 32px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 3;
  div {
    display: flex;
    flex-direction: column;
    background: white;
    z-index: 1005;
    padding-top: 86px;
    padding-bottom: 40px;
  }

  span {
    color: ${COLORS.grayscaleDarkmode};
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenuOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: calc(100vh + 70px);
  width: 100vw;
  background-color: rgba(45, 45, 64, 0.3);
`;

const Menu = styled.div`
  display: flex;
  align-items: center;

  div {
    height: 30px;
  }
  a {
    color: ${COLORS.grayscaleDarkmode};
    padding: 4px 0;
  }

  a:hover {
    color: ${COLORS.primaryBlue};
    border-bottom: 1px solid ${COLORS.primaryBlue};
  }
`;

const Wrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.div`
  display: flex;
  z-index: 1002;
  span {
    ${TYPOGRAPHY.logoReg24};
    color: ${COLORS.primaryBlue};
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const StyledScrollLink = styled(ScrollLink)`
  cursor: pointer;
  margin-right: 40px;
  :last-child {
    margin-right: 0;
  }
`;

export default Header;
