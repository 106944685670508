import { COLORS } from '@/constants';
import * as React from 'react';

interface ArrowToTopIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

function ArrowToTopIcon(props: ArrowToTopIconProps) {
  return (
    <svg
      width="8"
      height="27"
      viewBox="0 0 8 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.35355 0.646446C4.15829 0.451185 3.84171 0.451185 3.64644 0.646446L0.464463 3.82843C0.269201 4.02369 0.269201 4.34027 0.464463 4.53553C0.659726 4.7308 0.976308 4.7308 1.17157 4.53553L4 1.70711L6.82842 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.73079 4.34027 7.73079 4.02369 7.53553 3.82843L4.35355 0.646446ZM4.5 27L4.5 1L3.5 1L3.5 27L4.5 27Z"
        fill={props.color ? props.color : COLORS.white}
      />
    </svg>
  );
}

export default ArrowToTopIcon;
