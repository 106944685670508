export const TYPOGRAPHY = {
  title1Bold64: `    
    font-weight: 700;
    font-size: 64px;
    line-height: 87px;
  `,

  title2Bold44: `    
    font-weight: 700;
    font-size: 44px;
    line-height: 60px;    
  `,

  title3Bold28: `    
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;    
  `,

  title4Bold16: `    
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;    
  `,

  bodyReg16M: `    
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;    
  `,

  bodyReg18: `    
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;    
  `,

  bodyReg22: `    
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;    
  `,

  bodyBold22M: `    
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;    
  `,

  buttonSemiBold14: `    
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
  `,

  footerReg12: `
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `,
  logoReg24: `
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
  `
};
