import * as React from "react";

function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50"
      height="173"
      viewBox="0 0 50 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="19" width="10" height="170" rx="4" fill="#3380EE" />
      <rect
        y="149"
        width="10"
        height="34"
        rx="4"
        transform="rotate(-45 0 149)"
        fill="#3380EE"
      />
      <rect
        x="24"
        y="173"
        width="10"
        height="34"
        rx="4"
        transform="rotate(-135 24 173)"
        fill="#3380EE"
      />
    </svg>
  );
}

export default ArrowDown;
