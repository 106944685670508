import { COLORS, TYPOGRAPHY } from '.';

export const BUTTONS_THEME = {
  primary: `
    padding: 14px 30px;
    max-width: fit-content;
    ${TYPOGRAPHY.buttonSemiBold14};
    color: ${COLORS.white};
    background-color: ${COLORS.primaryBlue};
    border-radius: 10px;
    @media (max-width: 375px) {
      width: 100%;
      max-width: 100%;
    }
    &:hover{
      background-color: ${COLORS.primaryDarkBlue};
    }
    &:disabled {
      cursor: not-allowed;
      color: ${COLORS.white};
      background-color: ${COLORS.primaryAverage};
    }
    &:focus {
      
    }
  `,
  secondary: `
    padding: 14px 30px;
    max-width: fit-content;
    ${TYPOGRAPHY.buttonSemiBold14};
    color: ${COLORS.primaryBlue};
    background-color: ${COLORS.lightBlue};
    border-radius: 10px;
    @media (max-width: 375px) {
      width: 100%;
      max-width: 100%;
    }
    &:hover{
      background-color: ${COLORS.primaryAverage};
    }
  `,  
};
