import { BUTTONS_THEME } from "@/constants";
import { ButtonThemes } from "@/types";
import React, { ButtonHTMLAttributes, FC } from "react";
import styled from "styled-components";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  theme?: ButtonThemes;
}

const Button: FC<ButtonProps> = ({
  className,
  children,
  onClick,
  isDisabled = false,
  theme = "primary",
  ...outerProps
}) => {
  return (
    <Root
      className={className}
      onClick={onClick}
      disabled={isDisabled}
      $theme={theme}
      {...outerProps}
    >
      {children}
    </Root>
  );
};

const Root = styled.button<{ $theme: string }>`
  ${({ $theme }) => BUTTONS_THEME[$theme]};
`;

export default Button;
