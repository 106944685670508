import React, { FC } from "react";
import styled from "styled-components";

interface ContainerProps {
  className?: string;
}

const Container: FC<ContainerProps> = ({ children, className }) => (
  <Root className={className}>{children}</Root>
);

const Root = styled.div`
  position: relative;
  padding-left: 8.19%;
  padding-right: 8.19%;
  max-width: 1440px;
  margin: 0 auto;
  @media (min-width: 1440px) {
    padding-left: 118px;
    padding-right: 118px;
  }
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default Container;
