import * as React from "react";

function Line(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="49"
      viewBox="0 0 10 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="10" height="49" rx="4" fill="#3380EE" />
    </svg>
  );
}

export default Line;
